@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('assets/fonts/fonts.css');

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
